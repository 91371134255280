import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import {notification, Table, Button, Select, Input, Modal, Form} from "antd";
import IncludedPaymentService from "../../API/IncludedPaymentService";

const PaymentsTable = () => { 
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [payableEntities, setPayableEntities] = useState([]);
    const [newPaymentMethodModal, setNewPaymentMethodModal] = useState(false);
    const [newPaymentMethodForm] = Form.useForm();
    const [newPaymentTypeModal, setNewPaymentTypeModal] = useState(false);
    const [newPaymentTypeForm] = Form.useForm();
    const [options, setOptions] = useState([]);
    const [api, contextHolder] = notification.useNotification();
    
    const [fetchPayableEntities, isLoadingPayableEntities] = useFetching(async () => {
        try {
            const {data: payableEntities} = await IncludedPaymentService.getTypes();
            setPayableEntities(payableEntities);
        } catch (e) {
            console.log(e);
            api.open({
                message: `Ошибка при получении сущностей для оплаты`,
                description: `${e}`,
                duration: 4.5,
            });
        }
    });

    const [fetchPaymentMethods, isLoadingPaymentMethods] = useFetching(async () => {
        try {
            const {data: paymentMethods} = await IncludedPaymentService.getAll();
            setPaymentMethods(paymentMethods);
        } catch (e) {
            console.log(e);
            api.open({
                message: `Ошибка при получении способов оплаты`,
                description: `${e}`,
                duration: 4.5,
            });
        }
    });

    const columns = [
        {
            title: 'Нумерация',
            dataIndex: 'index',
            key: 'index',
            render: (text, record) => {
                return  <Input
                            defaultValue={record.index}
                            type={'number'}
                            onChange={(e) => handleChange(e.target.value, record, 'index')}
                        />
            }
        },
        {
            title: 'Название',
            dataIndex: 'paymentName',
            key: 'paymentName',
            render: (text, record) => {
                return <Input
                            defaultValue={record.paymentName}
                            onChange={(e) => handleChange(e.target.value, record, 'paymentName')}
                        />
            }
        },
        {
            title: 'Активно',
            dataIndex: 'active',
            key: 'active',
            render: (text, record) => {
                return  <Select
                            defaultValue={record.active}
                            style={{
                                width: 120,
                            }}
                            onChange={(e) => handleChange(e, record, 'active')}
                            options={[
                                {
                                    value: true,
                                    label: 'Активно',
                                },
                                {
                                    value: false,
                                    label: 'Не активно',
                                },
                            ]}
                        />
            }
        },
        {
            title: 'Включенные сущности',
            dataIndex: 'typeNames',
            key: 'typeNames',
            render: (text, record) => {
                return  <div>
                            <Select
                                mode="multiple"
                                style={{ width: '600px' }}
                                allowClear
                                placeholder="Please select"
                                defaultValue={record.paymentTypes.map((type) => type.name)}
                                onChange={(e) => handleChange(e, record, 'typeNames')}
                                options={options}
                            />
                        </div> 
            }
        },
        {
            title: 'Действия',
            dataIndex: 'actions',
            key: 'actions',
            render: (text, record) => {
                return  <Button
                            type={record.isEdited ? 'primary' : 'default'}
                            onClick={() => saveRow(record)}
                            style={{width: '200px'}}
                            disabled={!record.isEdited}
                        >
                            Сохранить
                        </Button>
            }
        }
    ];

    const handleChange = (value, record, column) => {
        const recordCopy = {...record};

        switch(column) {
            case 'paymentName':
                recordCopy.paymentName = value;
                break;
            case 'active':
                recordCopy.active = value;
                break;
            case 'typeNames':
                recordCopy.paymentTypes = value.map((type) => {
                    return {name: type}
                });
                break;
            case 'index':
                recordCopy.index = value;
                break;
            default:
                break;
        }

        recordCopy.isEdited = true;

        const paymentMethodsCopy = [...paymentMethods];
        const index = paymentMethodsCopy.findIndex((method) => method.id === record.id);
        paymentMethodsCopy[index] = recordCopy;
        setPaymentMethods(paymentMethodsCopy);
    };

    const saveRow = async (record) => {
        try {
            const parsedRow = {...record};
            parsedRow.typeNames = [];
            for (let i = 0; i < record.paymentTypes.length; i++) {
                parsedRow.typeNames.push(record.paymentTypes[i].name);
            }
            await IncludedPaymentService.update(parsedRow);
            api.open({
                message: `Успешно сохранено`,
                description: `Сохранение прошло успешно`,
                duration: 4.5,
            });
            const paymentMethodsCopy = [...paymentMethods];
            const index = paymentMethodsCopy.findIndex((method) => method.id === record.id);
            paymentMethodsCopy[index].isEdited = false;
            setPaymentMethods(paymentMethodsCopy);
        } catch (e) {
            console.log(e);
            api.open({
                message: `Ошибка при сохранении`,
                description: `${e}`,
                duration: 4.5,
            });
        }
    };

    const createNewPaymentMethod = async (values) => {
        console.log(values);
        try {
            await IncludedPaymentService.create(values);
            api.open({
                message: `Успешно добавлено`,
                description: `Добавление прошло успешно`,
                duration: 4.5,
            });
            setNewPaymentMethodModal(false);
            newPaymentMethodForm.resetFields();
            fetchPaymentMethods();
        } catch (e) {
            console.log(e);
            api.open({
                message: `Ошибка при добавлении`,
                description: `${e}`,
                duration: 4.5,
            });
        }
    };

    const createNewPaymentType = async (values) => {
        try {
            await IncludedPaymentService.createType(values);
            api.open({
                message: `Успешно добавлено`,
                description: `Добавление прошло успешно`,
                duration: 4.5,
            });
            setNewPaymentTypeModal(false);
            newPaymentTypeForm.resetFields();
            fetchPayableEntities();
        } catch (e) {
            console.log(e);
            api.open({
                message: `Ошибка при добавлении`,
                description: `${e}`,
                duration: 4.5,
            });
        }
    };

    useEffect(() => {
        const options = payableEntities.map((entity) => {
            return {label: entity.name, value: entity.name}
        });
        setOptions(options);
    }, [payableEntities]);

    useEffect(() => {
        fetchPayableEntities();
        fetchPaymentMethods();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {contextHolder}
            <Table columns={columns}
                    dataSource={paymentMethods}
                    rowKey='id'
                    loading={isLoadingPayableEntities || isLoadingPaymentMethods}
            />
            <Button onClick={() => setNewPaymentMethodModal(true)}>
                Добавить способ оплат
            </Button>
            <Button onClick={() => setNewPaymentTypeModal(true)}>
                Добавить сущность для оплаты
            </Button>
            <Modal
                title="Добавить способ оплаты"
                visible={newPaymentMethodModal}
                onCancel={() => setNewPaymentMethodModal(false)}
                footer={[
                    <Button key="submit" type="primary" form="methodForm" htmlType="submit">
                        Добавить
                    </Button>
                ]}
            >
                <Form
                    form={newPaymentMethodForm}
                    autoComplete="off"
                    id="methodForm"
                    onFinish={createNewPaymentMethod}
                >
                    <Form.Item
                        name="paymentName"
                        rules={[{required: true, message: 'Поле не может быть пустым!'}]}
                    >
                        <Input
                            placeholder="Название"
                        />
                    </Form.Item>
                    <Form.Item
                        name="active"
                        rules={[{required: true, message: 'Поле не может быть пустым!'}]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            placeholder="Пожалуйста, выберите"
                            options={
                                [
                                    {
                                        value: true,
                                        label: 'Активно',
                                    },
                                    {
                                        value: false,
                                        label: 'Не активно',
                                    },
                                ]
                            }
                        />
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Добавить сущность для оплаты"
                visible={newPaymentTypeModal}
                onCancel={() => setNewPaymentTypeModal(false)}
                footer={[
                    <Button key="submit" type="primary" form="methodForm" htmlType="submit">
                        Добавить
                    </Button>
                ]}
            >
                <Form
                    form={newPaymentTypeForm}
                    autoComplete="off"
                    id="methodForm"
                    onFinish={createNewPaymentType}
                >
                    <Form.Item
                        name="name"
                        rules={[{required: true, message: 'Поле не может быть пустым!'}]}
                    >
                        <Input
                            placeholder="Название"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default PaymentsTable;