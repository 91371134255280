import React, {useEffect, useState} from 'react';
import { Space, Table, Tag} from "antd";
import Modal from "antd/es/modal/Modal";
import {
    CheckCircleOutlined,
    MinusCircleOutlined,
    SyncOutlined
} from "@ant-design/icons";
import {useFetching} from "../../hooks/useFetching";
import FriendsAndFamilyService from "../../API/FriendsAndFamilyService";

const FriendsAndFamily = () => {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text, record) => (
                <Space size="middle">
                    <div>{record.id && record.id.substring(0, 6) + '...'}</div>
                </Space>
            )
        },
        {
            title: 'Карта',
            key: 'card',
            render: (text, record) => (
                <Space size="middle">
                    <div>{record.cardName}</div>
                </Space>
            )
        },
        {
            title: 'Отправитель',
            key: 'sender',
            render: (text, record) => (
                <Space size="middle">
                    <div>{record.sender && record.sender.firstName} {record.sender && record.sender.lastName}</div>
                </Space>
            )
        },
        {
            title: 'Email отправителя',
            render: (text, record) => (
                <Space size="middle">
                    <div>{record.sender && record.sender.email && record.sender.email.substring(0, 10) + '...'}</div>
                </Space>
            )
        },
        {
            title: 'Телефон отправителя',
            render: (text, record) => (
                <Space size="middle">
                    <div>{record.sender && record.sender.phone}</div>
                </Space>
            )
        },
        {
            title: 'Получатель',
            key: 'recipient',
            render: (text, record) => (
                <Space size="middle">
                    <div>{record.recipient && record.recipient.firstName} {record.recipient && record.recipient.lastName}</div>
                </Space>
            )
        },
        {
            title: 'Email получателя',
            render: (text, record) => (
                <Space size="middle">
                    <div>{record.recipient && record.recipient.email && record.recipient.email.substring(0, 10) + '...'}</div>
                </Space>
            )
        },
        {
            title: 'Телефон получателя',
            render: (text, record) => (
                <Space size="middle">
                    <div>{record.recipient && record.recipient.phone}</div>
                </Space>
            )
        },
        {
            title: 'Дата активации',
            render: (text, record) => (
                <Space size="middle">
                    <div>{record.activationDate && record.activationDate.substring(0, 10)}</div>
                </Space>
            )
        },
        {
            title: 'Дата деактивации',
            render: (text, record) => (
                <Space size="middle">
                    <div>{record.deactivationDate && record.deactivationDate.substring(0, 10)}</div>
                </Space>
            )
        },
        {
            title: 'Дата создания',
            render: (text, record) => (
                <Space size="middle">
                    <div>{record.createdAt && record.createdAt.substring(0, 10)}</div>
                </Space>
            )
        },
        {
            title: 'Дата обновления',
            render: (text, record) => (
                <Space size="middle">
                    <div>{record.updatedAt && record.updatedAt.substring(0, 10)}</div>
                </Space>
            )
        },
        {
            title: 'Статус',
            key: 'status',
            render: (text, record) => {
                if (record.status === 'PENDING') {
                    return <Tag icon={<SyncOutlined/>} color="processing">
                        В обработке
                    </Tag>
                } else if (record.status === 'ACTIVE') {
                    return <Tag icon={<CheckCircleOutlined/>} color="success">
                        Активный
                    </Tag>
                } else if (record.status === 'EXPIRED') {
                    return <Tag icon={<MinusCircleOutlined/>} color="default">
                        Просрочен
                    </Tag>
                }
            }
        },
    ];
    const modalColumns = [
        {
            title: 'param',
            dataIndex: 'param',
            key: 'param'
        },
        {
            title: 'value',
            dataIndex: 'value',
            key: 'value'
        }
    ];
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
    });
    const [selectedFFUser, setSelectedFFUser] = useState(null);
    const [parsedSelectedFFUser, setParsedSelectedFFUser] = useState(null);
    const [FFList, setFFList] = useState([]);
    const [fetchFFList, isLoading] = useFetching(async () => {
        const {data} = await FriendsAndFamilyService.getAll();
        setFFList(data.content);
        setPagination({
            current: data.pageable.pageNumber + 1,
            pageSize: data.pageable.pageSize,
            total: data.totalElements
        });
    });

    useEffect(() => {
        fetchFFList(pagination.current, pagination.pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        const recipient = selectedFFUser && selectedFFUser.recipient;
        const sender = selectedFFUser && selectedFFUser.sender;
        const parsedRecipient = recipient ? Object.entries(recipient)
            .map(([key, value]) => {
                if (key === 'email') {
                    return {param: 'Email получателя', value: value};
                }
                if (key === 'phone') {
                    return {param: 'Телефон получателя', value: value};
                }
                if (key === 'firstName') {
                    return {param: 'Имя получателя', value: value};
                }
                if (key === 'lastName') {
                    return {param: 'Фамилия получателя', value: value};
                } 
                return {param: key, value};
            }).filter(elem => elem.param !== 'id') : [];
        const parsedSender = sender ? Object.entries(sender)
            .map(([key, value]) => {
                if (key === 'email') {
                    return {param: 'Email отправителя', value: value};
                }
                if (key === 'phone') {
                    return {param: 'Телефон отправителя', value: value};
                }
                if (key === 'firstName') {
                    return {param: 'Имя отправителя', value: value};
                }
                if (key === 'lastName') {
                    return {param: 'Фамилия отправителя', value: value};
                }
                return {param: key, value};
            }).filter(elem => elem.param !== 'id') : [];
        setParsedSelectedFFUser([{
            param: 'ID',
            value: selectedFFUser && selectedFFUser.id
        }, ...parsedRecipient, ...parsedSender]);
    }, [selectedFFUser]);


    return (
        <>
            <Table columns={columns} dataSource={FFList} rowKey='id' loading={isLoading} onRow={(record, rowIndex) => {
                return {
                    onClick: event => {
                        setSelectedFFUser(record);
                    }
                }
            }} pagination={pagination}
            />
            <Modal
                title="FF Пользователь"
                visible={selectedFFUser}
                onCancel={() => setSelectedFFUser(null)}
                footer={null}
            >   
            <Table 
                columns={modalColumns} 
                dataSource={parsedSelectedFFUser}
                pagination={false}
                rowKey={(record) => record.param}
                showHeader={false}
            />
            </Modal>
        </>
    );
};

export default FriendsAndFamily;