import React, {useEffect, useState} from 'react';
import {Space, Table, Tag, Button, notification} from "antd";
import {useFetching} from "../../hooks/useFetching";
import PaymentService from "../../API/PaymentService";
import {CheckCircleOutlined, CloseCircleOutlined, MinusCircleOutlined, SyncOutlined} from "@ant-design/icons";

const Receipts = () => {
    const [api, contextHolder] = notification.useNotification();
    const [receipts, setReceipts] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
    });

    const columns = [
        {
            title: 'Оплата через',
            dataIndex: 'serviceType',
            key: 'serviceType'
        },
        {
            title: 'Тип',
            key: 'type',
            render: (text, record) => {
                if (record.type === 'INCOME') {
                    return <Tag icon={<CheckCircleOutlined spin />} color="success">
                        Приход
                    </Tag>
                }  else if (record.type === 'INCOME_RETURN') {
                    return <Tag icon={<MinusCircleOutlined/>} color="default">
                        Возврат прихода
                    </Tag>
                }
            }
        },
        {
            title: 'InvoiceId',
            dataIndex: 'invoiceId',
            key: 'invoiceId'
        },
        {
            title: 'tipTopReceiptId',
            dataIndex: 'tipTopReceiptId',
            key: 'tipTopReceiptId'
        },
        {
            title: 'Кол-во неуспешных попыток',
            dataIndex: 'attempts',
            key: 'attempts'
        },
        {
            title: 'Статус',
            key: 'status',
            render: (text, record) => {
                if (record.status === 'CREATED') {
                    return <Tag icon={<SyncOutlined spin />} color="processing">
                        В обработке
                    </Tag>
                } else if (record.status === 'SENT' || record.status === 'COMPLETED') {
                    return <Tag icon={<CheckCircleOutlined spin />} color="success">
                        Отправлен в TipTop
                    </Tag>
                } else if (record.status === 'FAILED') {
                    return <Tag icon={<CloseCircleOutlined/>} color="error">
                        Ошибка отправки в TipTop
                    </Tag>
                } else if (record.status === 'CANCELLED') {
                    return <Tag icon={<MinusCircleOutlined/>} color="default">
                        Отменен
                    </Tag>
                }
            }
        },
        {
            title: 'Создан',
            dataIndex: 'createdAt',
            key: 'createdAt'
        },
        {
            title: 'Обновлен',
            dataIndex: 'updatedAt',
            key: 'updatedAt'
        },
        {
            title: 'Действия',
            key: 'action',
            render: (text, record) => {
                if (record.serviceType === 'EPAYMENT') {
                    return <Space size="middle">
                        <Button type="danger" onClick={() => cancelInvoice(record.invoiceId)}>Отменить</Button>
                    </Space>
                }
            }
        }
    ]

    const [cancelInvoice, isCancelingInvoice] = useFetching(async (id) => {
        try {
            await PaymentService.cancelById(id);
            await fetchReceipts(pagination.current, pagination.pageSize);
        } catch (e) {
            console.log(e);
            api.open({
                message: `Ошибка при отмене invoiceId`,
                description: `${e}`,
                duration: 4.5,
            });
        }
    });

    const [fetchReceipts, isReceiptsLoading] = useFetching(async (page, size) => {
        const {data} = await PaymentService.getReceipts(page, size);
        setReceipts(data.content);
        setPagination({
            current: data.pageable.pageNumber + 1,
            pageSize: data.pageable.pageSize,
            total: data.totalElements
        });
    });

    const handleTableChange = async (pagination) => {
        await fetchReceipts(pagination.current, pagination.pageSize);
    }

    useEffect(() => {
        fetchReceipts(pagination.current, pagination.pageSize);
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            {contextHolder}
            <Table
                columns={columns}
                dataSource={receipts}
                rowKey='id'
                loading={isReceiptsLoading || isCancelingInvoice}
                pagination={pagination}
                onChange={handleTableChange}
                size='small'
            />
        </div>
    )
};

export default Receipts;